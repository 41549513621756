<template>
  <div class="pb-4" style="background-color: #fff" id="page-pendaftaran">
    <b-container fluid>
      <b-row style="padding-top: 15px">
        <b-col cols="12" md="12" lg="8" xl="8">
          <div
            style="
              width: 100%;
              height: 40px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              background-color: ;
            "
          >
            <h6
              style="font-weight: 700; font-size: 22px; margin-bottom: 0" class="custom-title-color-black">
              Stok Opname
            </h6>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" lg="12" xl="12" class="p-0">
          <hr />
        </b-col>
      </b-row>
      <b-row class="m-0 p-0">
        <b-col cols="6">
          <label for="tanggal">Bulan - Tahun</label>
          <date-picker
            style="width: 100%"
            id="tanggal"
            format="MMMM - YYYY"
            type="month"
            v-model="dataFilter.tanggal"
          ></date-picker>
        </b-col>
        <b-col cols="6">
          <label for="ms_gudang_id">Gudang</label>
          <Multiselect
            id="ms_gudang_id"
            v-model="dataFilter.ms_gudang_id"
            :options="listGudang"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="nama_gudang"
            placeholder="-- Pilih Gudang --"
            size="sm"
          ></Multiselect>
        </b-col>
        <b-col cols="12">
          <div class="d-flex justify-content-end">
            <b-button 
              class="mr-2"
              :disabled="busy"
              @click="reset()"
              variant="danger"
            >Reset</b-button>
            <b-button
              :disabled="busy"
              @click="cari()"
              variant="success"
            >Cari</b-button>
          </div>
        </b-col>
        <b-col cols="12">
          <canvas id="myChart" ref="pendapatan_harian" style="width: 100%"></canvas>
        </b-col>
        <!-- <b-col cols="12">
          <b-table
            class="px-3"
            :items="listStockOpname"
            :fields="fieldsPendapatanHarian"
            responsive
            show-empty
            small
            bordered
            striped
            hover
            :busy="busy"
          >
            <template #cell(tanggal)="item">{{$moment(item.item.tanggal).format('LL')}}</template>
            <template #cell(jumlah)="item">{{$numberFormat(item.item.jumlah, 'idr')}}</template>
          </b-table>
        </b-col> -->
      </b-row>
    </b-container>
  </div>
</template>
<script>
import {Chart, registerables } from 'chart.js'
Chart.register(...registerables );
export default {
  components:{
  },
  data() {
    return {
      busy: false,
      myChart: null,
      dataFilter: {
        tanggal: null,
        ms_gudang_id: null,
      },
      listGudang: [],
      listStockOpname: [],
      fieldsPendapatanHarian: [
        {
          key: "tanggal",
          label: "Tanggal",
          sortDirection: "desc",
          sortable: true,
          class: "text-left",
        },
        {
          key: "jumlah",
          label: "Jumlah",
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
        },
      ],
    }
  },
  computed: {
    
  },
  activated() {
    const vm = this
    vm.myChart = new Chart(this.$refs.pendapatan_harian, {
      type: 'bar',
      data: {
        labels: [],
        datasets: [{
          label: 'My First Dataset',
          data: [],
          backgroundColor: [
            '#6574cd',
          ],
          borderColor: [
            '#9561e2',
          ],
          hoverOffset: 4
        }]
      },
      options: {
        plugins: {
          legend: {
            display: false
          },
        },
      }
    })
    vm.myChart.update()
    vm.reset()
    vm.getData()
  },
  methods: {
    async cari(){
      const vm = this
      vm.busy = false
      try {
        // console.log('vm.dataFilter.tanggal', vm.dataFilter.tanggal)
        const res = await vm.$axios.post('/stock_opname/grafik_stock_opname', {
          tahun: vm.$moment(vm.dataFilter.tanggal).format('YYYY'),
          bulan: vm.$moment(vm.dataFilter.tanggal).format('MM'),
          ms_gudang_id: vm.dataFilter.ms_gudang_id ? vm.dataFilter.ms_gudang_id.ms_gudang_id : null,
        })
        // console.log('stock_opname/grafik_stock_opname', res)
        if(res.data.status == 200){
          vm.listStockOpname = res.data.data
          const label = []
          const data = []
          for (let i = 0; i < vm.listStockOpname.length; i++) {
            const x = vm.listStockOpname[i];
            label.push(vm.$moment(x.tanggal).format('DD'))
            data.push(x.jumlah)
          }
          vm.myChart.data.labels = label;
          vm.myChart.data.datasets[0].data = data;
          vm.myChart.update()
        }
      } catch (error) {
        console.log(error)
      } finally {
        vm.busy = false
      }
    },
    async getData(){
      const vm = this
      vm.busy = false
      try {
        const listGudang = await vm.$axios.post('/ms_gudang/list')
        vm.listGudang = listGudang.data.status == 200 ? listGudang.data.data : []
        console.log('listGudang', listGudang)
      } catch (error) {
        console.log(error)
      } finally {
        vm.busy = false
      }
    },
    reset(){
      const vm = this
      // console.log('vm.$store.state.tanggal_sesi', vm.$store.state.tanggal_sesi)
      vm.dataFilter = {
        tanggal: new Date(vm.$store.state.tanggal_sesi),
        ms_gudang_id: new Date(vm.$store.state.tanggal_sesi),
      }
      vm.cari()
    }
  },
}
</script>